import theme from '@assets/themes/theme'
import { Table, TableBody, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CustomTableCell } from './DtpPaidInvoiceAccordionUtills'

const InvoiceTable = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()
  return (
    <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 24px' }}>
      <TableHead>
        <TableRow>
          <CustomTableCell>
            <Typography variant="bodySmallEmphasized" color={theme.palette.text.secondary}>
              {t('invoices.invoice_date')}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="bodySmallEmphasized" color={theme.palette.text.secondary}>
              {t('invoices.invoice_number')}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="bodySmallEmphasized" color={theme.palette.text.secondary}>
              {t('invoices.invoice_amount')}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="bodySmallEmphasized" color={theme.palette.text.secondary}>
              {t('invoices.invoice_document')}
            </Typography>
          </CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  )
}

export default InvoiceTable
