import { cardPalette } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import { TableRow, TableCell, styled } from '@mui/material'

export const CustomTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: cardPalette.stackBgColor,
    boxShadow: `0 0 0 10px ${cardPalette.stackBgColor}`,
    borderRadius: theme.borderRadius.small,
    cursor: 'pointer',
  },
})

export const CustomTableCell = styled(TableCell)({
  padding: 0,
  border: 'none',
  textAlign: 'left',
  verticalAlign: 'center',
})
