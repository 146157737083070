import theme from '@assets/themes/theme'
import { IInvoice } from '@interfaces/invoices'
import { Box, Divider, Stack, useMediaQuery } from '@mui/material'

import { CustomTableRow } from './DtpPaidInvoiceAccordionUtills'
import InvoiceRowDesktop from './InvoiceRowDesktop'
import InvoiceRowMobile from './InvoiceRowMobile'
import InvoiceTable from './InvoiceTable'

const DtpPaidInvoiceItem = ({ data, multipleContracts }: { data: IInvoice[]; multipleContracts: boolean }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      {isMobile ? (
        <Stack direction="column" spacing={1.5} divider={<Divider />}>
          {data.map((row, index) => (
            <Box key={index}>
              <InvoiceRowMobile row={row} multipleContracts={multipleContracts} />
            </Box>
          ))}
        </Stack>
      ) : (
        <InvoiceTable>
          {data.map((row, index) => (
            <CustomTableRow key={index}>
              <InvoiceRowDesktop row={row} multipleContracts={multipleContracts} />
            </CustomTableRow>
          ))}
        </InvoiceTable>
      )}
    </>
  )
}

export default DtpPaidInvoiceItem
