import { DownloadIcon, DocumentIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import { IInvoice } from '@interfaces/invoices'
import { Box, Typography, Stack, Button } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { CustomTableCell } from './DtpPaidInvoiceAccordionUtills'

const InvoiceRowDesktop = ({ row, multipleContracts }: { row: IInvoice; multipleContracts: boolean }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <>
      <CustomTableCell>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          columnGap="24px"
          onClick={() => navigate(`${t('navigation_url.invoices')}/${row.invoiceId}`)}
        >
          <DocumentIcon sx={{ width: 48, height: 48, fill: '#EDEEED' }} />
          <Box>
            <Typography variant="bodySmallEmphasized" sx={{ margin: 0 }}>
              {t('invoices.invoice') +
                ` (${dayjs(row.billingPeriodFromDate).format('DD.MM.YY')} - ${dayjs(row.billingPeriodToDate).format(
                  'DD.MM.YY'
                )})`}
            </Typography>
            <Show when={multipleContracts}>
              <Typography
                variant="bodySmall"
                sx={{ margin: 0, fontFamily: 'GothamBook', fontWeight: 400, color: theme.palette.text.secondary }}
              >
                {row.contractId}
              </Typography>
            </Show>
          </Box>
        </Stack>
      </CustomTableCell>
      <CustomTableCell>
        <Typography
          variant="bodySmall"
          sx={{
            fontFamily: 'GothamBook',
            fontWeight: 400,
            color: theme.palette.text.secondary,
          }}
        >
          {row.invoiceId}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="bodySmallEmphasized">{`${row.amountTotal} ${row.currency}`}</Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Button
          disableRipple
          size="medium"
          variant="text"
          sx={{
            p: 0,
            minWidth: 'max-content',
            '.MuiButton-startIcon': {
              transition: 'color .15s ease-out, background-color .15s ease-out',
              borderRadius: '50%',
              padding: '4px',
              '& > :nth-of-type(1)': {
                width: '18px',
                height: '18px',
              },
            },
            '&:hover .MuiButton-startIcon': {
              color: '#FFF',
              backgroundColor: theme.palette.primary.main,
              transition: 'color .15s ease-out, background-color .15s ease-out',
            },
          }}
          startIcon={<DownloadIcon />}
        >
          PDF
        </Button>
      </CustomTableCell>
    </>
  )
}

export default InvoiceRowDesktop
