import { CheckIcon } from '@assets/icons'
import { invoiceStatus } from '@enums/invoiceStatus'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, styled, Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import CardDotItem from './CardDotItem'
import { CardItem } from './CardItem'

export const DtpBillsCardWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(3),
  border: '1px solid #D5D8D5',
  maxWidth: 369,
  minWidth: 330,
  bgcolor: theme.palette.grey[50],
  cursor: 'pointer',
  transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
  transition: 'all 0.4s ease',
  '&:hover': {
    boxShadow: '0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A',
  },
}))

export interface IDtpInvoiceCardProps {
  invoiceId: string
  dueDate: string
  invoiceStatus: invoiceStatus
  amountTotal: string
  currency: string
  contractId: string
  eBill: boolean
}

const DtpInvoiceCard = ({
  contractId,
  invoiceId,
  dueDate,
  amountTotal,
  currency,
  eBill,
  invoiceStatus,
}: IDtpInvoiceCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <DtpBillsCardWrapper
      role="open-invoice-card"
      onClick={() => navigate(`${t('navigation_url.invoices')}/${invoiceId}`)}
    >
      <Stack direction="row" alignItems="center" sx={{ mt: 0, mb: 1.5 }}>
        <Typography variant="bodyRegularEmphasized" sx={{ my: 0, mr: 2 }}>
          {contractId}
        </Typography>
        <ArrowForwardIcon color="primary" fontSize="small" />
      </Stack>
      <CardItem title={t('invoices.invoice_nr')} value={invoiceId} />
      <CardDotItem
        title={t('invoices.invoice_due_date')}
        value={dayjs(dueDate).format('DD.MM.YYYY')}
        invoiceStatus={invoiceStatus}
      />
      <CardItem title={t('invoices.invoice_amount')} value={`${currency} ${amountTotal}`} bold />
      <Show when={eBill}>
        <Stack flexDirection="row" alignItems="center" mt={2}>
          <Box mt={0.5}>
            <CheckIcon color="success" fontSize="small" />
          </Box>
          <Typography variant="bodyRegular" sx={{ my: 0, ml: 1 }}>
            {t('invoices.invoice_ebill')}
          </Typography>
        </Stack>
      </Show>
    </DtpBillsCardWrapper>
  )
}

export default DtpInvoiceCard
